import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { NewFavoritedMaterialReceipt, User, FavoritedMaterialReceipt, User_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { record_type, unit_type, union_type, string_type, class_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { MaterialReceipt_$reflection } from "../../CView.DTOs/Inventory.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { DateTimeOffset_formatNullable, AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { append, sumBy } from "../fable_modules/fable-library.4.10.0/Array.js";
import { op_Addition } from "../fable_modules/fable-library.4.10.0/Decimal.js";
import Decimal from "../fable_modules/fable-library.4.10.0/Decimal.js";
import { defaultMaterialReceipt } from "../Domain/Inventory.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Toast_text, Toast_title, Toast_success, Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { deleteFavoriteMaterialReceipt, createFavoriteMaterialReceipt } from "../Api/UserApi.fs.js";
import { findMaterialReceipt } from "../Api/InventoryApi.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { empty, map, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { formatDecimalWithUnits, formatInt } from "../Domain/Formatter.fs.js";

export class State extends Record {
    constructor(User, MaterialReceiptId, MaterialReceiptDeferred, FollowMaterialReceipt, UnFollowMaterialReceipt) {
        super();
        this.User = User;
        this.MaterialReceiptId = MaterialReceiptId;
        this.MaterialReceiptDeferred = MaterialReceiptDeferred;
        this.FollowMaterialReceipt = FollowMaterialReceipt;
        this.UnFollowMaterialReceipt = UnFollowMaterialReceipt;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.MaterialReceiptDetails.State", [], State, () => [["User", User_$reflection()], ["MaterialReceiptId", class_type("System.Guid")], ["MaterialReceiptDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [MaterialReceipt_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", MaterialReceipt_$reflection()]], [["ErrorValue", string_type]]]))], ["FollowMaterialReceipt", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["UnFollowMaterialReceipt", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoOp", "MaterialReceiptFollowed", "MaterialReceiptUnfollowed", "Search"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.Pages.MaterialReceiptDetails.Intent", [], Intent, () => [[], [["Item1", class_type("System.Guid")], ["Item2", string_type]], [["Item1", class_type("System.Guid")], ["Item2", string_type]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetMaterialReceipt", "FollowMaterialReceipt", "UnFollowMaterialReceipt", "BreadcrumbClicked"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.MaterialReceiptDetails.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [MaterialReceipt_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", MaterialReceipt_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], []]);
}

function getMaterialReceiptItemCount(state) {
    const matchValue = state.MaterialReceiptDeferred;
    let matchResult, materialReceipts;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            matchResult = 0;
            materialReceipts = matchValue.fields[0].fields[0];
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return sumBy((x) => x.QuantityReceived, materialReceipts.MaterialReceiptItems, {
                GetZero: () => (new Decimal("0")),
                Add: op_Addition,
            });
        default:
            return new Decimal(0);
    }
}

function toMaterialReceipt(state) {
    const matchValue = state.MaterialReceiptDeferred;
    switch (matchValue.tag) {
        case 1:
            return defaultMaterialReceipt;
        case 2:
            if (matchValue.fields[0].tag === 0) {
                return matchValue.fields[0].fields[0];
            }
            else {
                return defaultMaterialReceipt;
            }
        default:
            return defaultMaterialReceipt;
    }
}

export function init(materialReceiptId, user) {
    return [new State(user, materialReceiptId, new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, [])), singleton((dispatch) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    })];
}

export function update(msg, state) {
    let bind$0040, bind$0040_1;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                const materialReceipt = toMaterialReceipt(state);
                return [new State((msg.fields[0].fields[0].tag === 1) ? state.User : ((bind$0040 = state.User, new User(bind$0040.Id, bind$0040.UserTypeId, bind$0040.Email, bind$0040.DisplayName, bind$0040.IsActive, bind$0040.CanViewAirShipments, bind$0040.CanViewOceanShipments, bind$0040.CanViewGroundShipments, bind$0040.CanCreateBookings, bind$0040.CanViewBookings, bind$0040.CanExportSearchResults, bind$0040.CanViewInventory, bind$0040.CanViewAnalytics, bind$0040.CanViewRailShipments, bind$0040.CanViewCO2Emissions, bind$0040.CanViewBilling, bind$0040.CanViewShipments, bind$0040.CanExportBookings, bind$0040.CanExportInventory, bind$0040.CanExportBilling, bind$0040.CanExportDocuments, bind$0040.Accounts, bind$0040.Shipments, bind$0040.PurchaseOrders, append([new FavoritedMaterialReceipt(materialReceipt.Id, materialReceipt.MaterialReceiptNumber)], state.User.MaterialReceipts), bind$0040.PackingLists, bind$0040.UserPreferences, bind$0040.UserAnalyticsReports))), state.MaterialReceiptId, state.MaterialReceiptDeferred, new Deferred$1(2, [msg.fields[0].fields[0]]), state.UnFollowMaterialReceipt), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Pin Material Receipt", Alert_text(msg.fields[0].fields[0].fields[0]))) : Toast_success(Toast_title("Pin Material Receipt", Toast_text("You have successfully pinned the material receipt to the dashboard"))), (msg.fields[0].fields[0].tag === 1) ? (new Intent(0, [])) : (new Intent(1, [materialReceipt.Id, materialReceipt.MaterialReceiptNumber]))];
            }
            else if (equals(state.FollowMaterialReceipt, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.MaterialReceiptId, state.MaterialReceiptDeferred, new Deferred$1(1, []), state.UnFollowMaterialReceipt), Cmd_OfAsyncWith_perform((computation_1) => {
                    startImmediate(computation_1);
                }, createFavoriteMaterialReceipt, new NewFavoritedMaterialReceipt(state.MaterialReceiptId), (arg_2) => (new Msg(1, [new AsyncMsg$1(1, [arg_2])]))), new Intent(0, [])];
            }
        case 2:
            if (msg.fields[0].tag === 1) {
                const materialReceipt_1 = toMaterialReceipt(state);
                return [new State((msg.fields[0].fields[0].tag === 1) ? state.User : ((bind$0040_1 = state.User, new User(bind$0040_1.Id, bind$0040_1.UserTypeId, bind$0040_1.Email, bind$0040_1.DisplayName, bind$0040_1.IsActive, bind$0040_1.CanViewAirShipments, bind$0040_1.CanViewOceanShipments, bind$0040_1.CanViewGroundShipments, bind$0040_1.CanCreateBookings, bind$0040_1.CanViewBookings, bind$0040_1.CanExportSearchResults, bind$0040_1.CanViewInventory, bind$0040_1.CanViewAnalytics, bind$0040_1.CanViewRailShipments, bind$0040_1.CanViewCO2Emissions, bind$0040_1.CanViewBilling, bind$0040_1.CanViewShipments, bind$0040_1.CanExportBookings, bind$0040_1.CanExportInventory, bind$0040_1.CanExportBilling, bind$0040_1.CanExportDocuments, bind$0040_1.Accounts, bind$0040_1.Shipments, bind$0040_1.PurchaseOrders, state.User.MaterialReceipts.filter((userMaterialReceipt) => (userMaterialReceipt.MaterialReceiptId !== materialReceipt_1.Id)), bind$0040_1.PackingLists, bind$0040_1.UserPreferences, bind$0040_1.UserAnalyticsReports))), state.MaterialReceiptId, state.MaterialReceiptDeferred, state.FollowMaterialReceipt, new Deferred$1(2, [msg.fields[0].fields[0]])), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Unpin Material Receipt", Alert_text(msg.fields[0].fields[0].fields[0]))) : Toast_success(Toast_title("Unpin Material Receipt", Toast_text("You have successfully unpinned the material receipt from the dashboard"))), (msg.fields[0].fields[0].tag === 1) ? (new Intent(0, [])) : (new Intent(2, [materialReceipt_1.Id, materialReceipt_1.MaterialReceiptNumber]))];
            }
            else if (equals(state.UnFollowMaterialReceipt, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.MaterialReceiptId, state.MaterialReceiptDeferred, state.FollowMaterialReceipt, new Deferred$1(1, [])), Cmd_OfAsyncWith_perform((computation_2) => {
                    startImmediate(computation_2);
                }, deleteFavoriteMaterialReceipt, state.MaterialReceiptId, (arg_4) => (new Msg(2, [new AsyncMsg$1(1, [arg_4])]))), new Intent(0, [])];
            }
        case 3:
            return [state, Cmd_none(), new Intent(3, [])];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.User, state.MaterialReceiptId, new Deferred$1(2, [msg.fields[0].fields[0]]), state.FollowMaterialReceipt, state.UnFollowMaterialReceipt), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Material Receipt", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none(), new Intent(0, [])];
            }
            else if (equals(state.MaterialReceiptDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(0, [])];
            }
            else {
                return [new State(state.User, state.MaterialReceiptId, new Deferred$1(1, []), state.FollowMaterialReceipt, state.UnFollowMaterialReceipt), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, findMaterialReceipt, state.MaterialReceiptId, (arg) => (new Msg(0, [new AsyncMsg$1(1, [arg])]))), new Intent(0, [])];
            }
    }
}

export function render(state, dispatch) {
    let elems_26, elems_4, elems_2, elems, elems_1, elems_3, elems_17, elems_16, elems_8, elems_7, elems_6, elems_5, elems_15, elems_14, elems_13, elems_12, elems_25, elems_24;
    const matchValue = state.MaterialReceiptDeferred;
    let matchResult, materialReceipt;
    switch (matchValue.tag) {
        case 2: {
            if (matchValue.fields[0].tag === 0) {
                matchResult = 2;
                materialReceipt = matchValue.fields[0].fields[0];
            }
            else {
                matchResult = 0;
            }
            break;
        }
        case 1: {
            matchResult = 1;
            break;
        }
        default:
            matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return defaultOf();
        case 1:
            return render_1();
        default: {
            const isFavoritemMaterialReceipt = state.User.MaterialReceipts.some((s) => (s.MaterialReceiptId === state.MaterialReceiptId));
            return createElement("div", createObj(singleton((elems_26 = [createElement("nav", createObj(ofArray([["aria-label", "breadcrumbs"], ["className", join(" ", ["breadcrumb", "is-small", "box", "is-flex", "is-flex-wrap-wrap", "is-justify-content-space-between", "is-align-items-center"])], (elems_4 = [createElement("ul", createObj(ofArray([["className", join(" ", ["has-text-weight-semibold", "is-align-items-center"])], (elems_2 = [createElement("li", createObj(singleton((elems = [createElement("a", {
                className: "is-size-6",
                href: RouterModule_encodeParts(singleton("/inventory"), 1),
                onClick: (_arg) => {
                    dispatch(new Msg(3, []));
                },
                children: "Material Receipt",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))), createElement("li", createObj(ofArray([["className", join(" ", ["is-active", "is-size-6"])], (elems_1 = [createElement("a", {
                children: materialReceipt.MaterialReceiptNumber,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-content-center"])], (elems_3 = [createElement("span", {
                className: "tag",
                children: materialReceipt.StatusName,
            }), createElement("i", {
                className: join(" ", toList(delay(() => append_1(singleton_1("material-icons"), delay(() => append_1(singleton_1("mot-icon"), delay(() => append_1(singleton_1("m1-3"), delay(() => append_1(singleton_1("is-clickable"), delay(() => (isFavoritemMaterialReceipt ? singleton_1("has-text-link-dark") : singleton_1("has-text-grey-light"))))))))))))),
                title: "Favorite",
                children: "push_pin",
                onClick: (_arg_1) => {
                    if (isFavoritemMaterialReceipt) {
                        dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
                    }
                    else {
                        dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
                    }
                },
                disabled: equals(state.FollowMaterialReceipt, new Deferred$1(1, [])) ? true : equals(state.UnFollowMaterialReceipt, new Deferred$1(1, [])),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", "box"], (elems_17 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_16 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_8 = [createElement("div", createObj(singleton((elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-content-top", "is-flex-direction-column"])], (elems_6 = [createElement("h1", {
                className: join(" ", ["is-size-6", "has-text-weight-bold"]),
                children: materialReceipt.MaterialReceiptNumber,
            }), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-content-top", "is-flex-direction-row"])], (elems_5 = [createElement("span", {
                className: join(" ", ["has-text-weight-semibold"]),
                children: "Warehouse",
            }), createElement("span", {
                className: join(" ", ["mx-2"]),
                children: materialReceipt.WarehouseName,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_15 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_14 = [createElement("div", createObj(ofArray([["className", "column"], (elems_13 = [createElement("ul", createObj(ofArray([["className", join(" ", ["steps", "has-content-centered", "is-small"])], (elems_12 = toList(delay(() => map((milestone) => {
                let elems_11, elems_10, elems_9;
                return createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_1("steps-segment"), delay(() => append_1(!milestone.CompletedMilestone ? singleton_1("is-dashed") : empty(), delay(() => (milestone.LastMilestone ? singleton_1("is-active") : empty()))))))))], ["id", "527cabd8-c599-4630-96f0-018c2622de2e"], (elems_11 = [createElement("span", {
                    className: "steps-marker",
                }), createElement("div", createObj(ofArray([["className", "steps-content"], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], (elems_9 = [createElement("label", {
                    className: "has-text-weight-semibold",
                    children: milestone.Milestone,
                }), createElement("div", {
                    children: [DateTimeOffset_formatNullable(milestone.Date)],
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])));
            }, materialReceipt.Milestones))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))), createElement("div", createObj(ofArray([["className", "box"], (elems_25 = [createElement("h1", {
                className: join(" ", ["block", "has-text-weight-semibold"]),
                children: "MR Items",
            }), createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
                maxHeight: 250,
                overflowY: "auto",
            }], (elems_24 = toList(delay(() => {
                let elems_23, elems_19, elems_18, elems_22;
                return (materialReceipt.MaterialReceiptItems.length === 0) ? singleton_1(createElement("div", {
                    className: join(" ", ["has-text-centered"]),
                    children: "No material receipts found",
                })) : singleton_1(createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-hoverable", "is-fullwidth"])], (elems_23 = [createElement("thead", createObj(ofArray([["className", join(" ", [])], (elems_19 = [createElement("tr", createObj(ofArray([["className", join(" ", [])], (elems_18 = [createElement("th", {
                    className: join(" ", ["p-2"]),
                    children: "MR Item",
                }), createElement("th", {
                    className: join(" ", ["p-2"]),
                    children: "Status",
                }), createElement("th", {
                    className: join(" ", ["p-2"]),
                    children: "P/O Line Item",
                }), createElement("th", {
                    className: join(" ", ["p-2"]),
                    children: "P/O Number",
                }), createElement("th", {
                    className: join(" ", ["p-2"]),
                    children: "Quantity Received",
                }), createElement("th", {
                    className: join(" ", ["p-2"]),
                    children: "Part Number",
                }), createElement("th", {
                    className: join(" ", ["p-2"]),
                    children: "Description",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("tbody", createObj(ofArray([["className", join(" ", [])], (elems_22 = toList(delay(() => map((materialReceiptItem) => {
                    let elems_21, elems_20;
                    return createElement("tr", createObj(singleton((elems_21 = [createElement("td", {
                        className: join(" ", ["p-2"]),
                        children: materialReceiptItem.MaterialReceiptItem,
                    }), createElement("td", {
                        className: join(" ", ["p-2"]),
                        children: materialReceiptItem.StatusName,
                    }), createElement("td", {
                        className: join(" ", ["p-2"]),
                        children: formatInt(materialReceiptItem.PurchaseOrderLineNumber),
                    }), createElement("td", createObj(ofArray([["className", join(" ", ["p-2"])], (elems_20 = [createElement("a", {
                        className: join(" ", ["is-link", "is-underlined"]),
                        href: RouterModule_encodeParts(ofArray(["/purchase-order-details", materialReceiptItem.PurchaseOrderId]), 1),
                        children: materialReceiptItem.PurchaseOrderNumber,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]))), createElement("td", {
                        className: join(" ", ["p-2"]),
                        children: formatDecimalWithUnits(materialReceiptItem.QuantityReceivedUnit, materialReceiptItem.QuantityReceived),
                    }), createElement("td", {
                        className: join(" ", ["p-2"]),
                        children: materialReceiptItem.PartNumber,
                    }), createElement("td", {
                        className: join(" ", ["p-2"]),
                        children: materialReceiptItem.Description,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))]))));
                }, materialReceipt.MaterialReceiptItems))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])]))));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))]))));
        }
    }
}

