import { UserTypeId_Client } from "../../CView.DTOs/Constants.fs.js";
import { ClientUser, UserDataSources, User } from "../../CView.DTOs/DTOs.fs.js";

export const defaultUser = new User("00000000-0000-0000-0000-000000000000", UserTypeId_Client, "", "", false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0));

export const defaultUserDataSources = new UserDataSources(new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0));

export const defaultClientUser = new ClientUser("00000000-0000-0000-0000-000000000000", UserTypeId_Client, "", "", false, false, false, false, false, false, false, false, false, false, false, false, new Array(0));

export function isNewClientUser(user) {
    return user.Id === "00000000-0000-0000-0000-000000000000";
}

export function updatePermissions(user, globalAccountModules) {
    return new ClientUser(user.Id, user.UserTypeId, user.Email, user.DisplayName, user.IsActive, globalAccountModules.CanViewShipments && user.CanViewAirShipments, globalAccountModules.CanViewShipments && user.CanViewOceanShipments, globalAccountModules.CanViewShipments && user.CanViewGroundShipments, globalAccountModules.CanViewBookings && user.CanCreateBookings, globalAccountModules.CanViewBookings && user.CanViewBookings, user.CanExportSearchResults, globalAccountModules.CanViewInventory && user.CanViewInventory, globalAccountModules.CanViewAnalytics && user.CanViewAnalytics, globalAccountModules.CanViewShipments && user.CanViewRailShipments, globalAccountModules.CanViewShipments && user.CanViewCO2Emissions, globalAccountModules.CanViewBilling && user.CanViewBilling, user.Accounts);
}

